import axios from 'axios';
import {apis} from './endPoints';
import XMLParser from 'react-xml-parser';
import { config } from './constants';

const apiManager = {
    
    getPlans: (planid) => {
        let url = apis.ALL_PLANS_API;
        if(planid && planid !== "") {
            url = `${apis.PLAN_API}${planid}${config.url.STORE_URL}`;
        }
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(function (response) {
                    var data = response.data;
                    resolve(data);
                })
                .catch(function (error) {
                    reject({});
                });
        })
    },
    addStripeCards: (idToken, data) => {
        const url = `${apis.ADD_STRIPE_CARD}${idToken}`;
        return axios.post(url, data, {
            headers: { 'Content-Type': 'application/xml' }
        })
        .then(response => {
            const error = parseXmlError(response.data);
            if (error) {
                throw new Error(error);
            }
            return response.data;
        })
        .catch(error => {
            throw error;
        });
    },
    deleteStripeCards: (idToken, data) => {
        const url = `${apis.DELETE_STRIPE_CARD}${idToken}`;
        return axios.post(url, data, {
            headers: { 'Content-Type': 'application/xml' }
        })
        .then(response => {
            const error = parseXmlError(response.data);
            if (error) {
                throw new Error(error);
            }
            return response.data;
        })
        .catch(error => {
            throw error;
        });
    },
    getStripeCards: (idToken) => {
        const url = `${apis.GET_STRIPE_CARD}${idToken}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response) {
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
        })
    },
    getTitles: (url) => {
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response) {
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                var xml = new XMLParser().parseFromString(response.data);
                resolve(xml);
            })
            .catch(function (error) {
                reject(error);
            });
        })
    },
    getCustomFieldValues: (idToken) => {
        const url = `${apis.GET_CUSTOM_FIELDS_VALUES}${idToken}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response) {
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
        })
    },
    getPurchases: (idToken) => {
        const url = `${apis.GET_PURCHASES}${idToken}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response) {
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
        })
    },
    getSubscriptionStatus: (idToken) => {
        const url = `${apis.GET_SUBSCRIPTION_STATUS}${idToken}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response) {
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
        })
    },
    getCreditBalance: (idToken) => {
        const url = `${apis.GET_CREDIT_BALANCE}${idToken}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response) {
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
        })
    },
    cancelSubscription: (idToken) => {
        const url = `${apis.CANCEL_SUBSCRIPTION}${idToken}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
        })
    },
    getDevices: (idToken) => {
        const url = `${apis.GET_DEVICE}${idToken}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response) {
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
        })
    },
    deleteDevices: (idToken,data) => {
        const url = `${apis.DELETE_DEVICE}${idToken}`;
        return new Promise((resolve, reject) => {
            axios.post(url,data,{headers:{ 'Content-Type': 'application/xml' }})
            .then(function (response) {
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
        })
    },
    getThemeJson: () => {
        const url = apis.GET_THEME_JSON;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
        })
    },
    getPage: (urlParametersString) => {
        const url = `${apis.GET_PAGE}${urlParametersString}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    forgotPassword: (urlParametersString) => {
        const url = `${apis.FORGOT_PASSWORD}${urlParametersString}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                var xml = new XMLParser().parseFromString(response.data);
                resolve(xml);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    validateResetPasswordToken: (token) => {
        const url = `${apis.VALIDATE_RESET_PASSWORD_TOKEN}${token}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    resetPassword: (data) => {
        return new Promise((resolve, reject) => {
            axios.post(apis.RESET_PASSWORD, data, {
                headers: { 'Content-Type': 'application/xml' }
            })
            .then(function (response){
                var xml = new XMLParser().parseFromString(response.data);
                resolve(xml);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    login: (siteId, email, finalpassword) => {
        const url = `${apis.LOGIN_API}${siteId}`;
        return new Promise((resolve, reject) => {
            axios.get(url, {
                auth: {
                  username: email,
                  password: finalpassword
                }
            })
            .then(function (response){
                var xml = new XMLParser().parseFromString(response.data);
                resolve(xml);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    getCustomFields: (siteId) => {
        return new Promise((resolve, reject) => {
            axios.get(`${apis.GET_CUSTOM_FIELDS}${siteId}`)
            .then(function (response){
                var xml = new XMLParser().parseFromString(response.data);
                resolve(xml);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    signup: (siteId, data, email, finalpassword) => {
        const url = `${apis.SIGNUP_API}${siteId}`;
        return new Promise((resolve, reject) => {
            axios.post(url, data, {
                headers: { 'Content-Type': 'text/xml' },
                auth: {
                  username: email,
                  password: finalpassword
                }
              })
            .then(function (response){
                var xml = new XMLParser().parseFromString(response.data);
                resolve(xml);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    getTitleDetails: (appId) => {
        const url = `${apis.TITLE_DETAILS}${appId}${config.url.STORE_URL}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                var xml = new XMLParser().parseFromString(response.data);
                resolve(xml);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    getTitleDetail: (url) => {
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                var xml = new XMLParser().parseFromString(response.data);
                resolve(xml);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    getCoralToken: (idToken) => {
        const url = `${apis.GET_CORAL_TOKEN}${idToken}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    getStoreProperties: (token) => {
        return new Promise((resolve, reject) => {
            axios.get(apis.STORE_PROPERTIES+(token ? `?&accesstoken=${token}`: ''))
            .then(function (response){
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    getCategories: () => {
        return new Promise((resolve, reject) => {
            axios.get(apis.GET_CATEGORIES)
            .then(function (response){
                var xml = new XMLParser().parseFromString(response.data);
                resolve(xml);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    createSubscriptionCheckout : (idToken, planId, code, method) => {
        let url = `${apis.CHECKOUT_SUBSCRIPTION_SESSION}?planId=${planId}&accesstoken=${idToken}&code=${code}`;
        if (method) {
            url += `&method=${method}`;
        }
        
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    verifyRazorpaySignature : (idToken, paymentId, subscriptionId, signature) => {
        const url = `${apis.RAZORPAY_SIGN_VERIFY}?accesstoken=${idToken}&paymentId=${paymentId}&subscriptionId=${subscriptionId}&sign=${signature}`
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    createPaymentCheckout : (idToken, titleId,price, currency, code, giftInfo, method = "card") => {
        let url = `${apis.CHECKOUT_PAYMENT_SESSION}?titleId=${titleId}&price=${price}&currency=${currency}&accesstoken=${idToken}&code=${code}&method=${method}&giftInfo=` 
        if(giftInfo){
            const data = encodeURIComponent(JSON.stringify(giftInfo));
            url += `${data}`
        }
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    gotoCustomerPortal : (idToken, switchPlans) => {
        const url = `${apis.CUSTOMER_PORTAL_SESSION}?accesstoken=${idToken}&upgradeplan=${switchPlans}`
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    purchase: (idToken, planId, cardId, code) => {
        const url = `${apis.PURCHASE}?planId=${planId}&cardId=${cardId}&accesstoken=${idToken}&code=${code}`
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    confirmPayment: (intentId, subscriptionId, idToken) => {
        const url = `${apis.CONFIRM_PAYMENT}?intentId=${intentId}&subscriptionId=${subscriptionId}&accesstoken=${idToken}`
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    saveOverview: (siteId, data, email) => {
        const url = `${apis.OVERVIEW_API}${siteId}`;
        return new Promise((resolve, reject) => {
            axios.post(url, data, {
                headers: { 'Content-Type': 'application/xml' }
              })
            .then(function (response){
                // var xml = new XMLParser().parseFromString(response.data);
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    deleteUser: (idToken,finalpassword) => {
        const url = `${apis.DELETE_ACCOUNT}${idToken}`;
        return new Promise((resolve, reject) => {
            axios.get(url,{ 'headers': { 'Authorization': finalpassword } })
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                var xml = new XMLParser().parseFromString(response.data);
                resolve(xml);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    getRelatedTitles: (id) => {
        const url = `${apis.RELATED_TITLES}/${id}${config.url.STORE_URL}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response) {
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
        })
    },
    purchaseFreeTitle: (idToken, titleID) => {
        const url = `${apis.PURCHASE_FREE_TITLE}/${titleID}${config.url.STORE_URL}&accesstoken=${idToken}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response) {
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
        })
    },
    purchaseTitle: (idToken, titleID, cardId, price, currency, country, redeemCode) => {
        const url = `${apis.PURCHASE_TITLE}${titleID}${config.url.STORE_URL}&accesstoken=${idToken}&cardId=${cardId}&Price=${price}&currency=${currency}&country=${country}&RedeemPin=${redeemCode}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response) {
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
        })
    },
    getBulkPurchaseTitles: (url) => {
        // const url = `${apis.REDEEM_APPLY}${config.url.STORE_URL}&accesstoken=${idToken}&cardId=${cardId}&code=${code}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response) {
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
        })
    },
    confirmTitlePayment: (idToken, titleID, cardId, priceWithTax, taxPrice, intentId, taxRates, currency, country, redeemPin, transactonId, addressLine1, addressLine2, cardCity, cardState, cardCountry, giftInfo) => {
        let url = `${apis.CONFIRM_PAYMENT_TITLE}${titleID}${config.url.STORE_URL}&accesstoken=${idToken}&cardId=${cardId}&priceWithTax=${priceWithTax}&taxPrice=${taxPrice}&intentId=${intentId}&taxRates=${taxRates}&currency=${currency}&country=${country}&RedeemPin=${redeemPin}&transactonId=${transactonId}&addressLine1=${addressLine1}&addressLine2=${addressLine2}&cardCity=${cardCity}&cardState=${cardState}&cardCountry=${cardCountry}&giftInfo=`
        if(giftInfo){
            let data = encodeURIComponent(JSON.stringify(giftInfo));
            url += `${data}`
        }
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    userHasTitle: (titleID, idToken, transId) => {
        let url = `${apis.USER_HAS_APP}${titleID}${config.url.STORE_URL}&accesstoken=${idToken}&transId=`
        if(transId)
            url += `${transId}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    purchaseDetails: (transId, gift) => {
        let url = `${apis.PURCHASE_DETAILS}${config.url.STORE_URL}&transId=${transId}&isgift=${gift}`
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    applyRedeemCode: (idToken, redeemCode) => {
        if(idToken === null)
            idToken = "";
        const url = `${apis.APPLY_REDEEM_CODE}${config.url.STORE_URL}&accesstoken=${idToken}&code=${redeemCode}&country=&currency=`
       
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    applySubscriptionCode: (idToken, redeemCode) => {
        if(idToken === null)
            idToken = "";
        const url = `${apis.APPLY_REDEEM_CODE}${config.url.STORE_URL}&accesstoken=${idToken}&code=${redeemCode}&subscription=true&country=&currency=`
       
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    applyTitleRedeemCode: (titleID, idToken, redeemCode) => {
        if(idToken === null)
            idToken = "";
        const url = `${apis.APPLY_REDEEM_CODE}${config.url.STORE_URL}&accesstoken=${idToken}&code=${redeemCode}&titleid=${titleID}&country=&currency=`
       
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    redeemFreeWithCode: (titleID, idToken, redeemCode) => {
        if(titleID !== null)
            return apiManager.redeemFreeTitleWithCode(titleID, idToken, redeemCode);
        else{
            return apiManager.redeemFreeTitlesWithCode(idToken, redeemCode);
        
        }
    },
    redeemFreeTitleWithCode: (titleID, idToken, redeemCode) => {
        const url = `${apis.TITLE_REDEEM_FREE}${titleID}${config.url.STORE_URL}&accesstoken=${idToken}&RedeemPin=${redeemCode}`
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    checkoutSessionStatus: (transId) => {
        const url = `${apis.CHECKOUT_SESSION_STATUS}${config.url.STORE_URL}&transId=${transId}`
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    cartCheckoutSession: (idToken, redeemCode, giftInfo, method) => {
        let url = `${apis.CHECKOUT_CART_SESSION}?accesstoken=${idToken}&code=${redeemCode}&method=${method}&giftInfo=`
        if(giftInfo){
            const data = encodeURIComponent(JSON.stringify(giftInfo));
            url += `${data}`
        }
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    redeemFreeTitlesWithCode: (idToken, redeemCode) => {
        const url = `${apis.TITLES_REDEEM_FREE}${config.url.STORE_URL}&accesstoken=${idToken}&RedeemPin=${redeemCode}`
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    redeemFreeTitles: (idToken) => {
        const url = `${apis.TITLES_REDEEM_FREE}${config.url.STORE_URL}&accesstoken=${idToken}&RedeemPin=`
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    buyWithCredits: (titleID, idToken, credits, creditCurrency) => {
        const url = `${apis.PURCHASE_TITLE_WITH_CREDITS}${titleID}${config.url.STORE_URL}&accesstoken=${idToken}&credits=${credits}&creditCurrency=${creditCurrency}`
        return new Promise((resolve, reject) => {
            axios.post(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    getViewerComponents: (titleID, idToken, instanceid, sample) => {
        let url = `${apis.GET_VIEWER_COMPONENTS}${titleID}?accesstoken=${idToken}`;
        if(instanceid && instanceid !== "") {
            url = `${url}&instanceid=${instanceid}`
        }
        if(sample) {
            url = `${url}&sample=true`
        }
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    getViewerUrl: (titleID, idToken, instanceid, content, sample) => {
        content = content.replace('’', '\'');  // Remove ’ with ' as S3 doesnt support ’.
        let url = `${apis.GET_VIEWER_URL}${titleID}?accesstoken=${idToken}&instanceid=${instanceid}&content=${content}`;
        if(sample) {
            url = `${url}&sample=true`
        }
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    getTaxedPrice: (titleID, accessToken, price) => {
        const url = `${apis.GET_TAXED_PRICE}${titleID}&accesstoken=${accessToken}&price=${price}`
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    defaultStripeCard: (idToken, data) => {
        const url = `${apis.DEFAULT_CARD}${idToken}`;
        return new Promise((resolve, reject) => {
            axios.post(url, data, {
                headers: { 'Content-Type': 'application/xml' }
            })
            .then(function (response) {
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            });
        })
    },
    addToCart: (idToken, titleID, campaignId, code) => {
        const url = `${apis.ADD_CART_TITLE}${titleID}?campaignId=${campaignId}&code=${code}&accesstoken=${idToken}`
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    removeFromCart: (idToken, titleID, campaignId, code) => {
        const url = `${apis.REMOVE_CART_TITLE}${titleID}?campaignId=${campaignId}&code=${code}&accesstoken=${idToken}`
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    removeCampaignFromCart: (idToken, titleID, campaignId, code) => {
        const url = `${apis.REMOVE_CAMPAIGN_TITLE}${titleID}?campaignId=${campaignId}&code=${code}&accesstoken=${idToken}`
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                const error = parseXmlError(response);
                if(error) {
                    reject(error);
                }
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    },
    getTitlesJson: (url) => {
        return new Promise((resolve, reject) => {
          axios.get(url)
            .then(function (response) {
              const error = parseJsonError(response);
              if (error) {
                reject(error);
              }
              resolve(response.data);
            })
            .catch(function (error) {
              reject(error);
            });
        });
      },
      
    
    validateAccessToken: (token) => {
        const url = `${apis.VALIDATE_ACCESS_TOKEN}${token}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
            .then(function (response){
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
        })
    }
}
  



function parseXmlError(response) {
    if (typeof response.data === "string") {
        var xml = new XMLParser().parseFromString(response.data);
        if (xml && xml.name === "Error" && xml.children && xml.children.length > 0) {
            const statusCode = xml.children.find(e => e.name === "StatusCode");
            if (statusCode.value === "401") {
                return ({ statusCode: 401, status: "Unauthorized" });
            }
            const status = xml.children.find(e => e.name === "Status");
            if (status.value === "Invalid")
                return ({ statusCode: 0, status: "Invalid" });
            return (xml);
        }
    }
    return null;
}
function parseJsonError(response) {
    if (response.status !== 200) {
      return new Error(`HTTP Error: ${response.status}`);
    }
  
    // Check for specific error conditions in the JSON response
    if (response.data && response.data.error) {
      return new Error(`JSON Error: ${response.data.error}`);
    }
  
    // Add additional error conditions as needed
    // Example: if (response.data && response.data.errorMessage) { ... }
  
    return null; // No error found
  }
  

function checkUnauthorized(error) {
    if(error && error.statusCode && error.statusCode === 401) {
        return true;
    }
    return false;
}

export {checkUnauthorized,parseXmlError};

export default apiManager;