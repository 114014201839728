import { store } from "../store";
import apiManager from '@iso/src/ApiManager';


const actions = {
  UPDATE_SIDEBAR_CATEGORIES: 'UPDATE_SIDEBAR_CATEGORIES',
  UPDATE_CATEGORY_VIEW: 'UPDATE_CATEGORY_VIEW',
  updateCategory: (category) => ({
    type: actions.UPDATE_CATEGORY_VIEW,
    category
  }),
}
function updateCategories(){
  return function(dispatch){
    apiManager.getCategories()
      .then((response) => {
        let categories = [];
        const categoriesObject = response.children.find(e => e.name === "Categories")
        if(categoriesObject){
        // [{Name :"All" , TagId: ""}];
        for (const [_i, categoryObject] of categoriesObject.children.entries()) {
          let category = {};
          for (const [_j, categoryField] of categoryObject.children.entries()) {
            category[categoryField.name] = categoryField.value;
          }
          if(!category.TagId) {
            category['TagId'] = category.Id
          }
          categories.push(category)
        }
        store.dispatch({
          type : actions.UPDATE_SIDEBAR_CATEGORIES,
          categories
        })
      }
    })
      .catch((err) => {
        store.dispatch({
          type : actions.UPDATE_SIDEBAR_CATEGORIES,
          categories : []
        })
      })
  }
}
export default updateCategories;

export {actions};