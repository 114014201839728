const prod = {
  url: {
    API_URL: 'https://api.papertrell.com',
    API_URL_USERS: '',
    STORE_URL: '?',
  },
  STRIPE_LiveMode: true,
  STRIPE_KEY: "",
  GOOGLE_PLACES_API_KEY : "AIzaSyD8LVjYUHObR8fumETk_HVnL_oCR087qu4"
};
const staging = {
  url: {
    API_URL: 'https://shadowmblurb.papertrell.com',
    STORE_URL: '?storeurl=https://tflbookclubtest.v2.papertrell.com/',
  },
  STRIPE_LiveMode: false,
  GOOGLE_PLACES_API_KEY : "AIzaSyD8LVjYUHObR8fumETk_HVnL_oCR087qu4"
};
const dev = {
  url: {
    API_URL: 'http://localhost:83',
    STORE_URL: '?storeurl=https://youneekstudiostest.v2.papertrell.com',
    STORE_ORIGIN: 'https://shadowdemostore.papertrell.com',
  },
  STRIPE_LiveMode: false,
  GOOGLE_PLACES_API_KEY : "AIzaSyD8LVjYUHObR8fumETk_HVnL_oCR087qu4"
};

export const config = process.env.REACT_APP_ENV === 'prod' ? prod : (process.env.REACT_APP_ENV === 'staging'? staging : dev);
