const actions = {
  UPDATE_AUDIO_PLAYER: 'UPDATE_AUDIO_PLAYER',
  UPDATE_AUDIO_PLAYER_PROGRESS: 'UPDATE_AUDIO_PLAYER_PROGRESS',
  RESET_AUDIO_PLAYER: 'RESET_AUDIO_PLAYER',
  updateAudioPlayer: (player) => ({
    type: actions.UPDATE_AUDIO_PLAYER,
    player
  }),
  updateAudioPlayerProgress: (progress, audioPlayerCurrentTime) => ({
    type: actions.UPDATE_AUDIO_PLAYER_PROGRESS,
    progress,
    audioPlayerCurrentTime
  }),
  resetAudioPlayer: () => ({
    type: actions.RESET_AUDIO_PLAYER
  })
};
export default actions;
