import { config } from './constants';
const baseUrl = 'https://api.papertrell.com/';

// REACT_NATIVE TODO USE LOGIN TO UPDATE THE TOKEN
export const apis = {
    ACCESS_TOKEN : '',

    STORE_API : `${baseUrl}Store/home?accesstoken=`,

    EXPLORE_API : `${baseUrl}store/titles?accesstoken=`,

    CATEGORIES_API : `${baseUrl}store/categories?accesstoken=`,

    LIBRARY_API : `${baseUrl}library/titles?accesstoken=`,

    ABOUT_API : `${baseUrl}globalsettings/GetbyShelfID/`,

    SHELF_SETTINGS : `${baseUrl}Shelf/ShelfSettings?accesstoken=`,

    DETAILS_API : `${baseUrl}v2/titledetails/GetByTitleID/`,

    MY_STORES_API : `${baseUrl}UserShelfs/GetAllShelfsByUserId?accessToken=`,

    GET_TITLE_DOWNLOAD_URL_API : `${baseUrl}DownloadUrl/GetByTitleID/`,
    TITLE_PURCHASE_VERIFICATION_API : `${baseUrl}Purchase/TitlePurchaseVerification/`,

    GENERATE_NEW_ACCESS_TOKEN_FOR_SHELF : `${baseUrl}AccessToken/GetByShelfId/`,

    LOGIN_API_OLD : `${baseUrl}user/signin`,

    LOGIN_API : `${config.url.API_URL}/v2/user/Signin/access?appid=`,

    SIGNUP_API : `${config.url.API_URL}/v2/user/Signup/access?appid=`,

    OVERVIEW_API : `${config.url.API_URL}/v2/user/SaveUserDetails${config.url.STORE_URL}&accesstoken=`,

    FORGOT_PASSWORD: `${config.url.API_URL}/user/forgotpassword`,

    GET_CUSTOM_FIELDS: `${config.url.API_URL}/Store/CustomSignupFields/`,

    GET_CUSTOM_FIELDS_VALUES: `${config.url.API_URL}/v2/user/CustomFields${config.url.STORE_URL}&accesstoken=`,

    GET_JSON: `${config.url.API_URL}/v2/store/home?accesstoken=`,

    GET_THEME_JSON: `${config.url.API_URL}/v2/store/home${config.url.STORE_URL}`,

    GET_LIBRARY: `${config.url.API_URL}/library/titles?accesstoken=`,

    GET_PURCHASES: `${config.url.API_URL}/v2/user/Purchases${config.url.STORE_URL}&accesstoken=`,

    GET_SUBSCRIPTION_STATUS: `${config.url.API_URL}/v2/user/Subscription${config.url.STORE_URL}&accesstoken=`,

    CANCEL_SUBSCRIPTION: `${config.url.API_URL}/v2/user/CancelSubscription${config.url.STORE_URL}&accesstoken=`,

    DELETE_ACCOUNT:`${config.url.API_URL}/User/Delete${config.url.STORE_URL}&accesstoken=`,

    GET_DEVICE: `${config.url.API_URL}/v2/user/GetDeviceDetails${config.url.STORE_URL}&accesstoken=`,

    DELETE_DEVICE: `${config.url.API_URL}/v2/user/DeregisterDevice${config.url.STORE_URL}&accesstoken=`,

    RESET_PASSWORD: `${config.url.API_URL}/user/resetpassword`,

    VALIDATE_RESET_PASSWORD_TOKEN: `${config.url.API_URL}/user/token/`,

    TITLE_DETAILS: `${config.url.API_URL}/v2/TitleDetails/GetbyTitleID/`,

    TITLE_DETAILS_ISBN: `${config.url.API_URL}/v2/TitleDetails/GetbyIsbn/`,

    PURCHASE_FREE_TITLE: `${config.url.API_URL}/v2/Title/Purchase/FreeTitle/`,

    PURCHASE_TITLE: `${config.url.API_URL}/v2/Title/Purchase/`,

    USER_HAS_APP: `${config.url.API_URL}/v2/Title/UserHasApp/`,

    PURCHASE_DETAILS: `${config.url.API_URL}/v2/purchase/info`,

    CONFIRM_PAYMENT_TITLE: `${config.url.API_URL}/v2/Title/Purchase/Confirm/`,

    // TITLLE_REDEEM_CODE: `${config.url.API_URL}/v2/Title/ApplyPin/`,
    
    APPLY_REDEEM_CODE: `${config.url.API_URL}/v2/Redeem/Apply`,

    TITLE_REDEEM_FREE: `${config.url.API_URL}/v2/Title/Redeem/`,

    TITLES_REDEEM_FREE: `${config.url.API_URL}/v2/Titles/Redeem`,
    
    CHECKOUT_CART_SESSION: `${config.url.API_URL}/v2/checkout/cart/session`,

    CHECKOUT_SESSION_STATUS: `${config.url.API_URL}/v2/checkout/session/status`,
    
    PURCHASE_TITLE_WITH_CREDITS: `${config.url.API_URL}/v2/Title/Purchase/Credits/`,

    RELATED_TITLES: `${config.url.API_URL}/v2/Title/Related`,

    GET_CORAL_TOKEN: `${config.url.API_URL}/v2/coral/token?accesstoken=`,

    ALL_TITLES: `${config.url.API_URL}/store/titles${config.url.STORE_URL}&type=0`,

    CATALOG_TITLES: `${config.url.API_URL}/v2/catalog/titles${config.url.STORE_URL}`,
    
    TITLESET_TITLES: `${config.url.API_URL}/v2/titleset/titles${config.url.STORE_URL}`,

    ALL_EBOOK_TITLES: `${config.url.API_URL}/store/titles${config.url.STORE_URL}&type=1`,

    ALL_AUDIO_TITLES: `${config.url.API_URL}/store/titles${config.url.STORE_URL}&type=2`,

    ALL_VIDEO_TITLES: `${config.url.API_URL}/store/titles${config.url.STORE_URL}&type=4`,

    GET_CATEGORIES: `${config.url.API_URL}/store/categories${config.url.STORE_URL}`,

    CATEGORY_TITLES: `${config.url.API_URL}/store/titles${config.url.STORE_URL}`,
    // CATEGORY_TITLES: `${config.url.API_URL}/store/titles${config.url.STORE_URL}&tagid=`,

    STORE_PROPERTIES: `${config.url.API_URL}/v2/store/properties${config.url.STORE_URL}`,
    // STORE_PROPERTIES: `${config.url.API_URL}/v2/store/properties?storeurl=https://booklits1.papertrell.com`,//${config.url.STORE_URL}`,

    ADD_STRIPE_CARD: `${config.url.API_URL}/v2/card/add?accesstoken=`,

    DELETE_STRIPE_CARD: `${config.url.API_URL}/v2/card/Delete?accesstoken=`,

    GET_STRIPE_CARD: `${config.url.API_URL}/v2/card/All?accesstoken=`,

    CHECKOUT_SUBSCRIPTION_SESSION: `${config.url.API_URL}/v2/checkout/Subscription/Session`,
    
    RAZORPAY_SIGN_VERIFY: `${config.url.API_URL}/v2/razorpay/signature/verify`,

    CHECKOUT_PAYMENT_SESSION: `${config.url.API_URL}/v2/checkout/payment/Session`,

    CUSTOMER_PORTAL_SESSION: `${config.url.API_URL}/v2/customer/portal/session`,

    PURCHASE: `${config.url.API_URL}/v2/subscription/purchase`,

    CONFIRM_PAYMENT: `${config.url.API_URL}/v2/subscription/confirm`,

    // https://api.papertrell.com/user/stores?emailid=ackmedia@papertrell.com&shelfid=00000000-0000-0000-0000-000000000001
    GET_STOREFRONT_API : `${baseUrl}user/stores`,

    REGISTER_API : `${baseUrl}user/signup`,

    CUSTOM_SIGN_UP_API : `${baseUrl}store/customsignupfields/`,

    ACCESS_TOKEN_API : `${baseUrl}Home/AccessToken?oauth_consumer_key=consumerkey&oauth_signature_method=HMAC-SHA1&social_network_id=5&`,

    //https://api.papertrell.com/redeem/apply
    REDEEM_APPLY : `${config.url.API_URL}/v2/redeem/apply`,

    //https://api.papertrell.com/redeem
    REDEEM : `${baseUrl}redeem/accesstoken=`,

    //https://api.papertrell.com/store/titles?accesstoken=&page=&size=&categoryid=&tagid=&q=
    ALL_TITLES_URL : `${baseUrl}store/titles?accesstoken=`,

    //https://api.papertrell.com/coral/get/view?accesstoken=4a7dc8a014c8418ca815a109b247b170&titleid=005157423
    COMMENTS_URL : `${baseUrl}coral/get/view?accesstoken=`,

    GET_PAGE: `${config.url.API_URL}/v2/page/get${config.url.STORE_URL}`,

    ALL_PLANS_API : `${config.url.API_URL}/v2/plans/all${config.url.STORE_URL}`,
    PLAN_API : `${config.url.API_URL}/v2/plans/`,

    GET_VIEWER_COMPONENTS:  `${config.url.API_URL}/v2/viewer/components/`,

    GET_VIEWER_URL: `${config.url.API_URL}/v2/viewer/url/`,

    GET_TAXED_PRICE: `${config.url.API_URL}/v2/tax/get?titleid=`,

    DEFAULT_CARD: `${config.url.API_URL}/v2/card/change?accesstoken=`,

    GET_CREDIT_BALANCE: `${config.url.API_URL}/v2/user/credit/balance?accesstoken=`,

    GET_REWARD_TITLES: `${config.url.API_URL}/v2/campaign/reward/titles${config.url.STORE_URL}`,
    VALIDATE_ACCESS_TOKEN: `${config.url.API_URL}/v2/token/validate?accesstoken=`,
    
    ADD_CART_TITLE: `${config.url.API_URL}/v2/cart/add/`,

    REMOVE_CART_TITLE: `${config.url.API_URL}/v2/cart/remove/`,

    REMOVE_CAMPAIGN_TITLE: `${config.url.API_URL}/v2/cart/removeCampaign/`,

    GET_CART_TITLES: `${config.url.API_URL}/v2/cart/titles`,

};

// export const apis;