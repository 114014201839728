import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import themes from '@iso/config/theme/theme.config';
import AppLocale from '@iso/config/translation';
import updateCategories from './redux/webstore/actions';
import {updateTheme} from './redux/themeSwitcher/actions';

export default function AppProvider({ children }) {
  const dispatch = useDispatch();
  const { locale } = useSelector(state => state.LanguageSwitcher.language);
  const { themeName } = useSelector(state => state.ThemeSwitcher.changeThemes);
  const currentAppLocale = AppLocale[locale];
  dispatch(updateTheme());
  const { categories } = useSelector(state => state.Webstore);
  if(!categories || (categories && categories.length === 0) || categories === undefined) {
    dispatch(updateCategories());
  }
  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}
