export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin',
  SIGN_UP: '/signup',
  FORGET_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/resetpassword/:token',
  OLD_RESET_PASSWORD: '/resetpassword.aspx',
  PAGE_404: '/404',
  PAGE_500: '/500',
  AUTH0_CALLBACK: '/auth0loginCallback',
  SUBSCRIPTION: '/subscription',
  OLD_SUBSCRIPTION: '/subscription.aspx',
  TITLE_DETAILS: '/id:titleId/:name?',
  TITLE_DETAILS_ISBN: '/isbn:isbntitleId/:name?',
  ALL_TITLES: '/all',
  ALL_AUDIO_TITLES: '/audio',
  ALL_EBOOK_TITLES: '/ebook',
  ALL_VIDEO_TITLES: '/video',
  CATELOG_TITLES: '/catalog/titles/:id',
  TITLESET_TITLES: '/titleset/titles/:id',
  CATEGORY: '/all/:name?/:id?',
  CATEGORY_AUDIO_TITLES: '/audio/:name?/:id?',
  CATEGORY_EBOOK_TITLES: '/ebook/:name?/:id?',
  CATEGORY_VIDEO_TITLES: '/video/:name?/:id?',
  REDEEM: '/redeem',
  REDEEM_CODE: '/redeem/:code',
  PUBLIC_PAGE: '/pages/public/:page',
  PRIVATE_PAGE: '/pages/private/:page',
  ABOUT: '/about',
  TERMS: '/terms',
  PRIVACY: '/privacy',
  TITLE_PURCHASE: '/title/purchase',
  INVALID_STORE:'/invalid',
  CAMPAIGN: '/campaign/:id',
  CART_PURCHASE: '/purchase'
};

export const PRIVATE_ROUTE = {
  ACCOUNT: '/account',
  DASHBOARD: '/',
  MYLIBRARY: '/mylibrary',
  MYLIBRARY_BOOK_TYPE: '/mylibrary/:book_type',
  MYLIBRARY_EBOOK: '/mylibrary/ebook',
  MYLIBRARY_AUDIO: '/mylibrary/audio',
  MYLIBRARY_VIDEO: '/mylibrary/video',
  VIEWER: '/viewer',
  PDF_VIEWER: '/pdfviewer',
  PAYMENT: '/subscription/purchase',  
  SUBSCRIPTION_CHECKOUT_COMPLETE: '/checkout/complete',
  SUBSCRIPTION_PURCHASE_SUCCESS: '/subscription/purchase/success',
  TITLE_PURCHASE_SUCCESS: '/title/purchase/success',
  CART_CHECKOUT: '/cart/checkout',
  CART_PURCHASE_SUCCESS: '/cart/purchase/success',
  TABLE: '/table_ant',
  FORM: '/allFormComponent',
  INPUT: '/InputField',
  EDITOR: '/editor',
  FORM_WITH_STEPPERS: '/stepperForms',
  FORM_WITH_VALIDATION: '/FormsWithValidation',
  PROGRESS: '/progress',
  BUTTON: '/button',
  TAB: '/tab',
  AUTOCOMPLETE: '/autocomplete',
  CHECKBOX: '/checkbox',
  RADIOBOX: '/radiobox',
  SELECT: '/selectbox',
  TRANSFER: '/transfer',
  GRID_LAYOUT: '/gridLayout',
  NOTES: '/notes',
  TODO: '/todo',
  ARTICLES: '/articles',
  INVESTORS: '/investors',
  CONTACTS: '/contacts',
  ALERT: '/alert',
  MODAL: '/modal',
  MESSAGE: '/message',
  NOTIFICATION: '/notification',
  POPCONFIRM: '/Popconfirm',
  SPIN: '/spin',
  SHUFFLE: '/shuffle',
  AFFIX: '/affix',
  BREADCRUMB: '/breadcrumb',
  BACK_TO_TOP: '/backToTop',
  DROPDOWN: '/dropdown',
  OP_BADGE: '/op_badge',
  OP_CARD: '/op_card',
  OP_CAROUSEL: '/op_carousel',
  OP_COLLAPSE: '/op_collapse',
  OP_TOOLTIP: '/op_tooltip',
  RATING: '/rating',
  TREE: '/tree',
  OP_TAG: '/op_tag',
  OP_TIMELINE: '/op_timeline',
  OP_POPOVER: '/op_popover',
  GOOGLE_CHART: '/googleChart',
  RECHARTS: '/recharts',
  MENU: '/menu',
  REACT_CHART_2: '/ReactChart2',
  PAGINATION: '/pagination',
  CARD: '/card',
  CART: '/cart',
  CHECKOUT: '/checkout',
  SHOP: '/shop',
  REACT_DATES: '/reactDates',
  CODE_MIRROR: '/codeMirror',
  UPPY: '/uppy',
  DROPZONE: '/dropzone',
  YOUTUBE_SEARCH: '/youtubeSearch',
  FRAPPE_CHART: '/frappeChart',
  INVOICE: '/invoice',
  CHAT: '/chat',
  PROFILE: '/my-profile',
  SWIPER_SLIDER: '/swiperslider',
};
