import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import { store } from './redux/store';
import history from '@iso/lib/helpers/history';
import TagManager from 'react-gtm-module'
import AppStyleWrapper from './app.style';

const tagManagerArgs = {
  gtmId: 'GTM-NXWRHJ'
}
TagManager.initialize(tagManagerArgs)

const App = () => {
  window.dataLayer.push({
    event: 'pageview'
  });
  return (
    <Provider store={store}>
      <AppProvider>
        <>
          <GlobalStyles />
          <AppStyleWrapper>
            <Routes history={history} />
          </AppStyleWrapper>
        </>
      </AppProvider>
    </Provider>
  )
};
Boot()
  .then(() =>
    App())
  .catch(error => console.error(error));

export default App;
