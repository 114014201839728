import styled from 'styled-components';
import WithDirection from '@iso/lib/helpers/rtl';
import CustomizedThemeColor from './settings/customTheme';

const AppStyleWrapper = styled.div`
    .custom-shelf-color {
        background-color: ${CustomizedThemeColor};
    }
`;

export default WithDirection(AppStyleWrapper);
