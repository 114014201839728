import {actions} from './actions';

const initState = {
  categories: null,
  category: null
};

export default function(state = initState, action) {
  switch (action.type) {
    case actions.UPDATE_SIDEBAR_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case actions.UPDATE_CATEGORY_VIEW:
      return {
        ...state,
        category: action.category,
      }
    default:
      return state;
  }
}
