import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './root-reducer';
import rootSaga from './root-saga';

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const store = createStore(persistReducer({
  key: "root",
  debug: true,
  storage,
  blacklist: ['App']
  // whitelist: ['Auth'],
}, rootReducer),
  composeEnhancers(applyMiddleware(...middlewares))
);

const persistor = persistStore(store, null, () => {
  // if you want to get restoredState
});
sagaMiddleware.run(rootSaga);
export { store, persistor };