import actions from './actions';
const initState = {
  audioPlayerProgress: 0,
  currentAudioItemId: null,
  audioPlayerCurrentTime: 0
};

export default function playerReducer(state = initState, action) {
  switch (action.type) {
    case actions.UPDATE_AUDIO_PLAYER:
      return {
        ...state,
        audioPlayerProgress: action.player.progress,
        currentAudioItemId: action.player.itemId,
        audioPlayerCurrentTime: action.player.audioPlayerCurrentTime? action.player.audioPlayerCurrentTime : 0
      }
    case actions.UPDATE_AUDIO_PLAYER_PROGRESS:
      return {
        ...state,
        audioPlayerProgress: action.progress,
        audioPlayerCurrentTime: action.audioPlayerCurrentTime
      }
    case actions.RESET_AUDIO_PLAYER:
        return initState;
    default:
      return state;
  }
}
