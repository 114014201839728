import actions from './actions';
const initState = {
  hideSidebar: false,
  hideSubscribe: true,
  hideSearch: false,
  hideLibrary: false,
  hideHome: false,
  hideSignin: false,
  VideoListImg: null,
  isSample: false,
  TitleID: null,
  VideoListTitle: null,
  VideoList: false,
  hideTopbar: false,
  hideTopFilter:true,
  topBarFilter: null,
  hideFooter: false,
  cartCount: 0,
  hideAccount: false,
  hideExplore: false,
  showOpenApp: false
};

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.TOPBAR_BUTTON_VISIBILITY_CHANGE:
      return {
        ...state,
        hideSidebar: ((action.hideSidebar && action.hideSidebar!== undefined) || action.hideSidebar === false) ? action.hideSidebar : state.hideSidebar,
        hideSubscribe: action.hideSubscribe,
        hideSearch: action.hideSearch,
        hideLibrary: action.hideLibrary,
        hideHome: action.hideHome,
        hideSignin: action.hideSignin,
        VideoListImg: ((action.VideoList && action.VideoList!== undefined) || action.VideoList === false) ? action.VideoListImg : state.VideoListImg,
        isSample:  !!action.isSample,
        TitleID: ((action.VideoList && action.VideoList!== undefined) || action.VideoList === false) ? action.TitleID : state.TitleID,
        VideoListTitle: ((action.VideoList && action.VideoList!== undefined) || action.VideoList === false) ? action.VideoListTitle : state.VideoListTitle,
        VideoList: ((action.VideoList && action.VideoList!== undefined) || action.VideoList === false) ? action.VideoList : state.VideoList,
        hideTopbar: ((action.hideTopbar && action.hideTopbar!== undefined) || action.hideTopbar === false) ? action.hideTopbar : state.hideTopbar,
        hideTopFilter:((action.hideTopFilter && action.hideTopFilter!== undefined) || action.hideTopFilter === false) ? action.hideTopFilter : state.hideTopFilter,
        // topBarFilterValue: action.hideTopFilter === true? null : action.hideTopFilter === undefined ? (state. hideTopFilter === true ? null : state.topBarFilterValue) : state.topBarFilterValue
        hideFooter: ((action.hideFooter && action.hideFooter!== undefined) || action.hideFooter === false) ? action.hideFooter : state.hideFooter,
        hideAccount :action.hideAccount,
        hideExplore: action.hideExplore,
        showOpenApp: action.showOpenApp ? action.showOpenApp : false
      };
    case actions.CHANGE_TOPBAR_FILTER_VALUE:
    return {
      ...state,
      topBarFilterValue: action.topBarFilterValue
    }
    case actions.UPDATE_CART:
      return {
        ...state,
        cartCount: action.cartCount
      }
    default:
      return state;
  }
}
