import React from 'react';
import FourZeroFourStyleWrapper from './InvalidStore.styles';

export default function() {
  return (
    <FourZeroFourStyleWrapper className="iso404Page">
      <div className="iso404Content">
        <h1>
        Oops!
        </h1>
        <h3>
        You seem to be lost.
        </h3>
        <p>
        We don’t recognize the url or storefront you are looking for. Kindly double check your spelling and try again.<br/>Please contact us online or email <a href="mailto:support@papertrell.com">support@papertrell.com</a> and we’ll get right back to you.
        </p>
      </div>
    </FourZeroFourStyleWrapper>
  );
}