import { getCurrentTheme } from './config';
import { store } from "../store";
import apiManager,{parseXmlError} from '@iso/src/ApiManager';

const actions = {
  CHANGE_THEME: 'CHANGE_THEME',
  CHANGE_LOGO: 'CHANGE_LOGO',
  CHANGE_STORE_PROPERTIES: 'CHANGE_STORE_PROPERTIES',
  SWITCH_ACTIVATION: 'SWITCH_ACTIVATION',
  CHANGE_FAVICON: 'CHANGE_FAVICON',
  CHANGE_SUBSCRIPTION: 'CHANGE_SUBSCRIPTION',
  RESET_SUBSCRIPTION: 'RESET_SUBSCRIPTION',
  UPDATE_CREDIT_BALANCE: 'UPDATE_CREDIT_BALANCE',
  UPDATE_GIFT_INFO: 'UPDATE_GIFT_INFO',
  switchActivation: () => ({
    type: actions.SWITCH_ACTIVATION,
  }),
  changeTheme: (attribute, themeName) => {
    const theme = getCurrentTheme(attribute, themeName);
    if (attribute === 'layoutTheme') {
      document.getElementsByClassName(
        'isomorphicContent'
      )[0].style.backgroundColor = theme.backgroundColor;
    }
    return {
      type: actions.CHANGE_THEME,
      attribute,
      theme,
    };
  },
  resetSubscription: () => ({
    type: actions.RESET_SUBSCRIPTION
  }),
  resetCreditBalance: () => ({
    type: actions.UPDATE_CREDIT_BALANCE,
    CreditBalance: 0,
    CreditCurrency: '',
  }),
  updateSubscription: (enableSubscription) => ({
    type: actions.CHANGE_SUBSCRIPTION,
    EnableSubscription: !!enableSubscription
  }) 
};

function ColorLuminance(hex, lum) {

	// validate hex string
	hex = String(hex).replace(/[^0-9a-f]/gi, '');
	if (hex.length < 6) {
		hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
	}
	lum = lum || 0;

	// convert to decimal and change luminosity
	var rgb = "#", c, i;
	for (i = 0; i < 3; i++) {
		c = parseInt(hex.substr(i*2,2), 16);
		c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
		rgb += ("00"+c).substr(c.length);
	}

	return rgb;
}

function updateTheme() {
  return function (dispatch) {
    const token = localStorage.getItem("id_token");

    apiManager.getStoreProperties(token)
      .then((response) => {
        let shelfProps = response.data;
        let error = parseXmlError(response);
        if ((error && error.statusCode === 0 && error.status === "Invalid") || (shelfProps.ShelfId === undefined)) {
          store.dispatch({
            type: actions.CHANGE_STORE_PROPERTIES,
            siteInvalid: true
          });
          return;
        }
       
        store.dispatch({
          type: actions.CHANGE_THEME,
          attribute: "sidebarTheme",
          theme: {
            // "themeName": "theme3",
            buttonColor: shelfProps.ShelfColor,
            backgroundColor: shelfProps.ShelfColor,
            textColor: "#ffffff",
            footerColor: ColorLuminance(shelfProps.ShelfColor, -0.3)
          }
        });

        // if (!shelfProps.ShelfLogo || shelfProps.ShelfLogo === "")
        //   return;
        
        let FavIcon = shelfProps.FavIcon
        FavIcon = (FavIcon && FavIcon !== "") ? FavIcon : "/favicon.ico"
        store.dispatch({
          type: actions.CHANGE_STORE_PROPERTIES,
          siteLogoSrc: shelfProps.ShelfLogo,
          siteName: shelfProps.ShelfName,
          siteFavIcon: FavIcon,
          sitePlaystoreLink: shelfProps.AppStoreUrls?.find(e=> e.Type === "GooglePlay")?.Url ?? "https://play.google.com/store/apps/details?id=com.papertrell" ,
          siteApplestoreLink: shelfProps.AppStoreUrls?.find(e=> e.Type === "Apple")?.Url ?? "https://apps.apple.com/in/app/papertrell-v2/id6451319528",
          StoreEnableSubscription:shelfProps.EnableSubscription === false? false: true,
          EnableSubscription: shelfProps.EnableSubscription === false? false: true,
          EnableEbook: shelfProps.EnableEbook === false? false : true,
          EnableAudioTitle: shelfProps.EnableAudioTitle === true? true : false,
          EnableVideoTitle: shelfProps.EnableVideoTitle === true? true : false,
          EbooksDisplayText: shelfProps.EbooksDisplayText && shelfProps.EbooksDisplayText !== undefined &&  shelfProps.EbooksDisplayText !== ""? shelfProps.EbooksDisplayText : "eBooks",
          AudioTitlesDisplayText: shelfProps.AudioTitlesDisplayText && shelfProps.AudioTitlesDisplayText !== undefined &&  shelfProps.AudioTitlesDisplayText !== ""? shelfProps.AudioTitlesDisplayText : "Audio Books",
          VideoTitlesDisplayText: shelfProps.VideoTitlesDisplayText && shelfProps.VideoTitlesDisplayText !== undefined &&  shelfProps.VideoTitlesDisplayText !== ""? shelfProps.VideoTitlesDisplayText : "Video Books",
          siteId: shelfProps.ShelfId,
          about: shelfProps.About,
          terms : shelfProps.TermsOfService,
          termsUrl : shelfProps.TermsOfServiceUrl,
          privacy:shelfProps.PrivacyPolicy,
          privacyUrl:shelfProps.PrivacyPolicyUrl,
          SupportEmail: shelfProps.SupportEmail,
          //siteInvalid : shelfProps.StoreBuilderVersion === "2"? false: true,
          siteInvalid : false,
          ActionAfterPurchaseConfirmation: shelfProps.ActionAfterPurchaseConfirmation,
          ActionAfterPurchaseConfirmationLinkText: shelfProps.ActionAfterPurchaseConfirmationLinkText,
          CookieConsentUrl: shelfProps.CookieConsentUrl,
          EnableWebSubscriptionRedeem: shelfProps.EnableWebSubscriptionRedeem === false? false : true,
          EnableWebTitleRedeem: shelfProps.EnableWebTitleRedeem === false? false : true,
          SubscriptionMessageHtml: shelfProps.SubscriptionMessageHtml,
          ShowBuyTitle:shelfProps.ShowBuyTitle,
          ShowCookieConsent: shelfProps.ShowCookieConsent === true? true: false,
          EnableWebReader: shelfProps.EnableWebReader === false? false : true,
          Faq: shelfProps.Faq ? shelfProps.Faq : "https://papertrell.freshdesk.com/support/solutions/folders/3000010468",
          EnableTax: shelfProps.EnableTax === true? true : false,
          ShowTitlePrice: shelfProps.ShowTitlePrice === false? false : true,
          CustomCode:shelfProps.CustomCode,
          ShowPapertrellBrand: shelfProps.ShowPapertrellBrand === false? false : true,
          AllTitlesSortOptions: shelfProps.AllTitlesSortOptions ? JSON.parse(shelfProps.AllTitlesSortOptions) : [],
          MyLibrarySortOptions: shelfProps.MyLibrarySortOptions ? JSON.parse(shelfProps.MyLibrarySortOptions) : [],
          titleText: (shelfProps.TitlesDisplayText && shelfProps.TitlesDisplayText !== "") ? shelfProps.TitlesDisplayText : 'Titles',
          EnableBuyWithCredits: shelfProps.EnableBuyWithCredits ? shelfProps.EnableBuyWithCredits : false,
          ShowNewsletterControls: shelfProps.ShowNewsletterControls,
          NewsletterSignupText: shelfProps.NewsletterSignupText,
          PurchaseCountries: shelfProps.PurchaseCountries,
          CreditsPerRenewal: shelfProps.CreditsPerRenewal ? shelfProps.CreditsPerRenewal : 0,
          //CreditCurrency: shelfProps.CreditsPerRenewal ? 'Credit' : ''
          CreditCurrency: shelfProps.CreditCurrency ? shelfProps.CreditCurrency : '',
          EnableMblurb: shelfProps.EnableMblurb,
          SubscribeButtonText :shelfProps.SubscribeButtonText,
          SubscribeButtonSubText :shelfProps.SubscribeButtonSubText,
          HideFilters: shelfProps.HideFilters ? shelfProps.HideFilters : false,
          EnableGiftingTitle: shelfProps.EnableGiftingTitle ? shelfProps.EnableGiftingTitle : false,          
          MyCatalogDisplayText: shelfProps.MyCatalogDisplayText && shelfProps.MyCatalogDisplayText !== undefined &&  shelfProps.MyCatalogDisplayText !== ""? shelfProps.MyCatalogDisplayText : "My Catalog",
          ShowRelatedTitles: shelfProps.ShowRelatedTitles !== undefined ? shelfProps.ShowRelatedTitles : true,
          ShelfColor: shelfProps.ShelfColor,
          ShopifyShop: shelfProps.ShopifyShop ? shelfProps.ShopifyShop : '',
          StoreUrl: shelfProps.StoreUrl,
          StoreUrlAlt: shelfProps.StoreUrlAlt ? shelfProps.StoreUrlAlt : '',
        });

        if(shelfProps.EnableSubscription !== false && token && token !== "" && token !== undefined) {
          apiManager.getSubscriptionStatus(token)
          .then(function(resp) {
            store.dispatch({
              type: actions.CHANGE_SUBSCRIPTION,
              SubscriptionData : resp.data.data,
              EnableSubscription: !(resp.data.subscribed)         
            });
          });
        }
        if(token && token !== "" && token !== undefined && shelfProps.EnableBuyWithCredits) {
          apiManager.getCreditBalance(token)
          .then(function(resp) {
            store.dispatch({
              type: actions.UPDATE_CREDIT_BALANCE,
              CreditBalance: resp.data.CreditBalance,
              CreditCurrency: resp.data.CreditCurrency,
              //EnableBuyWithCredits : resp.data.EnableBuyWithCredits
            });
          });
        }

      })
      .catch((err) => {
        store.dispatch({
          type: actions.CHANGE_FAVICON,
          siteFavIcon: "/favicon.ico"
        });
        console.log("Error on updating theme", err)
      })
  }
}

function updateSubscription() {
  return function (dispatch) {
    const token = localStorage.getItem("id_token");
    if(token && token !== "" && token !== undefined) {
      apiManager.getSubscriptionStatus(token)
      .then(function(resp) {
        store.dispatch({
          type: actions.CHANGE_SUBSCRIPTION,
          EnableSubscription: !(resp.data.subscribed),
          SubscriptionData : resp.data.data
        });
      });
    }
  }
}

function updateCreditBalance() {
  return function (dispatch) {
    const token = localStorage.getItem("id_token");
    if(token && token !== "" && token !== undefined) {
      apiManager.getCreditBalance(token)
      .then(function(resp) {
        store.dispatch({
          type: actions.UPDATE_CREDIT_BALANCE,
          CreditBalance: resp.data.CreditBalance,
          CreditCurrency: resp.data.CreditCurrency,
          EnableBuyWithCredits : resp.data.EnableBuyWithCredits
        });
      });
    }
  }
}

function updateGiftInfo(data) {
  return function (dispatch) {
        store.dispatch({
          type: actions.UPDATE_GIFT_INFO,
          RecipientName: data.RecipientName,
          RecipientEmail: data.RecipientEmail,
          RecipientMessage : data.RecipientMessage
        });
  }
}

export default actions;

export {updateTheme, updateSubscription, updateCreditBalance, updateGiftInfo};