import qs from 'qs';
import options from '../../containers/Sidebar/options';
import { isServer } from './isServer';

export function getInitData() {
  if (!isServer) {
    const initData = qs.parse(window.location.search.slice(1));
    if (initData.toggle)
      initData.toggle.free_shipping =
        initData.toggle.free_shipping === 'true' ? true : undefined;
    return initData;
  }
  return false;
}
export function setUrl(searchState) {
  if (!isServer) {
    const search = searchState
      ? `${window.location.pathname}?${qs.stringify(searchState)}`
      : '';
    window.history.pushState(searchState, null, search);
  }
  return;
}

export function getDefaultPath() {
  const getParent = lastRoute => {
    const parent = [];
    if (!lastRoute) return parent;
    parent.push(lastRoute);
    options.forEach(option => {
      if (option.children) {
        option.children.forEach(child => {
          if (child.key === lastRoute) {
            parent.push(option.key);
          }
        });
      }
    });

    const root = localStorage.getItem("persist:root");
    const webstore = JSON.parse(root? root : "{}")?.Webstore;
    let storage = JSON.parse(webstore? webstore: "{}")
    if(!storage)
      return parent;
      
    let categories = storage && storage.categories ? storage.categories : []
    categories.forEach(category => {
      if (category.Name.replace(/\s+/g, '-').toLowerCase() === lastRoute) {
        parent.push("Categories");
      }
    });
    if(window.location.pathname === "/all/all") {
      parent.push("Categories");
    }
    return parent;
  };

  if (!isServer && window.location.pathname) {
    const routes = window.location.pathname.split('/');
    if(routes.length === 4) {
      const lastRoute = routes[2];
      return getParent(lastRoute);
    }else if (routes.length === 3 && ((routes.includes("ebook") && routes.includes("all")) || (routes.includes("audio") && routes.includes("all")))) {
      const lastRoute = routes[1];
      return getParent(lastRoute);
    }else if (routes.length > 1) {
      const lastRoute = routes[routes.length - 1];
      return getParent(lastRoute);
    }
  }
  return [];
}

export function isOnHomePage() {
  let onHomePage
  if (!isServer && window.location.pathname) {
    const route = window.location.pathname;
    if(route === "/") {
      onHomePage = true;
    }
  }
  return onHomePage;
}