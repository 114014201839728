const options = [
  // {
  //   key: 'ebook',
  //   label: 'sidebar.ebook',
  //   leftIcon: 'ion-ios-book',
  //   link: '/ebook/all'
  // },
  // {
  //   key: 'audio',
  //   label: 'sidebar.audio',
  //   leftIcon: 'ion-headphone',
  //   link: '/audio/all'
  // },
  // {
  //   key: 'video',
  //   label: 'sidebar.video',
  //   leftIcon: 'ion-ios-videocam',
  //   link: '/video/all'
  // },
  // {
  //   key: 'authCheck',
  //   label: 'sidebar.authCheck',
  //   leftIcon: 'ion-document',
  // },
];
export default options;
