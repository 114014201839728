import React from 'react';
import FourZeroFourStyleWrapper from './InvalidStore.styles';
import { Link } from 'react-router-dom';

export default function() {
  return (
    <FourZeroFourStyleWrapper className="iso404Page">
      <div className="iso404Content">
        <h1>
        Oops!
        </h1>
        <h3>
        Wrong Turn
        </h3>
        <p>
        We don’t recognize the page you are looking for. Kindly double check your spelling and try again.<br/>Please contact us online or email <a href="mailto:support@papertrell.com">support@papertrell.com</a> and we’ll get right back to you.
        </p>
        <Link to="/">
          <button type="button">
            Back to Home
          </button>
        </Link>
      </div>
    </FourZeroFourStyleWrapper>
  );
}