import { getDefaultPath, isOnHomePage } from '@iso/lib/helpers/url_sync';
import actions, { getView } from './actions';
import { isServer } from '@iso/lib/helpers/isServer';
let preKeys = getDefaultPath();
const current = preKeys;

if(preKeys.length <= 1) {
  preKeys = preKeys.concat(['Categories']);
}
const onHomePage = isOnHomePage();
// preKeys.push("Categories");
const initState = {
  collapsed: !isServer && window.innerWidth > 1220 ? false : true,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  current: current,
  showHomeButton: !onHomePage,
  searchMode: false,
  searchPage: "/",
  searchQuery: "",
  searchBarValue: "",
  routes_: []
};

export default function appReducer(state = initState, action) {
  switch (action.type) {
    case actions.COLLPSE_CHANGE:
      return {
        ...state,
        collapsed: !state.collapsed,
      };
    case actions.COLLPSE_OPEN_DRAWER:
      return {
        ...state,
        openDrawer: !state.openDrawer,
      };
    case actions.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return {
          ...state,
          collapsed: action.collapsed,
          view: action.view,
          height,
        };
      }
      break;
    case actions.CHANGE_OPEN_KEYS:
      return {
        ...state,
        openKeys: action.openKeys,
      };
    case actions.CHANGE_CURRENT:
      return {
        ...state,
        current: action.current,
        showHomeButton: false
      };
    case actions.CLEAR_MENU:
      return {
        ...state,
        openKeys: [],
        current: [],
      };
    case actions.UPDATE_HOME_BUTTON_VISIBILITY:
      return {
        ...state,
        showHomeButton: action.showHomeButton,
      };
    case actions.UPDATE_SEARCH:
      return {
        ...state,
        searchMode: action.searchMode,
        searchPage: action.searchPage,
        searchQuery: action.searchQuery,
      };
    case actions.UPDATE_SEARCH_BAR_VALUE: 
      return {
        ...state,
        searchBarValue: action.searchBarValue
      };
    case actions.BREADCRUMBS_ROUTES: 
      return {
        ...state,
        routes_: action.routes_
      };
    default:
      return state;
  }
  return state;
}
