import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE, PRIVATE_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';
import history from '@iso/lib/helpers/history';
import { Helmet } from 'react-helmet'
import persistApp, { getButtonVisibilityFromPath, getTopBarFilerValueFromPath } from './redux/persistApp/actions';
import InvalidStore from '@iso/containers/Home/InvalidStore';
import InvalidPage from '@iso/containers/Home/InvalidPage';
import appActions from '@iso/redux/app/actions';
import util from "@iso/helpers/utilities";

const { changeTopbarButtonVisibility, changeTopbarFilterValue } = persistApp;
const { changeOpenKeys, changeCurrent } = appActions;

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    exact: true,
    component: lazy(() => import('@iso/containers/public/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import('@iso/containers/public/SignUp/SignUpWrapper')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() => import('@iso/containers/public/ForgotPassword/ForgotPassword')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() => import('@iso/containers/public/ResetPassword/ResetPassword')),
  },
  {
    path: PUBLIC_ROUTE.SUBSCRIPTION,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.OLD_SUBSCRIPTION,
  },
  {
    path: PUBLIC_ROUTE.OLD_RESET_PASSWORD,
  },
  {
    path: PUBLIC_ROUTE.TITLE_PURCHASE,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.TITLE_DETAILS,
    exact: true,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.TITLE_DETAILS_ISBN,
    exact: true,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.ALL_TITLES,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.CATELOG_TITLES,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.TITLESET_TITLES,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.ALL_EBOOK_TITLES,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.ALL_AUDIO_TITLES,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.ALL_VIDEO_TITLES,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.CATEGORY,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.CATEGORY_EBOOK_TITLES,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.CATEGORY_AUDIO_TITLES,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.CATEGORY_VIDEO_TITLES,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.REDEEM,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.REDEEM_CODE,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.PRIVATE_PAGE,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard'))
  },
  {
    path: PUBLIC_ROUTE.PUBLIC_PAGE,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard'))
  },
  {
    path: PUBLIC_ROUTE.ABOUT,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.TERMS,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.PRIVACY,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.CAMPAIGN,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PUBLIC_ROUTE.CART_PURCHASE,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard'))
  }  
];

const privateRoutes = [
  {
    path: PRIVATE_ROUTE.MYLIBRARY,
    exact: true,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PRIVATE_ROUTE.MYLIBRARY_BOOK_TYPE,
    exact: true,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PRIVATE_ROUTE.VIEWER,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PRIVATE_ROUTE.PDF_VIEWER,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PRIVATE_ROUTE.PAYMENT,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PRIVATE_ROUTE.ACCOUNT,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  },
  {
    path: PRIVATE_ROUTE.SUBSCRIPTION_PURCHASE_SUCCESS,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard'))
  },
  {
    path: PRIVATE_ROUTE.TITLE_PURCHASE_SUCCESS,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')) 
  },
  {
    path: PRIVATE_ROUTE.CART_PURCHASE_SUCCESS,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard')),
  }, 
  {
    path: PRIVATE_ROUTE.SUBSCRIPTION_CHECKOUT_COMPLETE,
    component: lazy(() => import('@iso/containers/Checkout/Complete'))
  },
  {
    path: PRIVATE_ROUTE.CART_CHECKOUT,
    component: lazy(() => import('@iso/containers/Dashboard/Dashboard'))
  }
];

const RestrictedRoute = ({ component: Component, isLoggedIn, siteInvalid, ...rest }) => {
  const { pathname } = window.location;
  const dispatch = useDispatch();
  dispatch(changeOpenKeys(['Categories']));
  dispatch(changeCurrent(['all']));
  const buttonVisibility = getButtonVisibilityFromPath(rest.path);
  dispatch(changeTopbarButtonVisibility(buttonVisibility));
  const topBarFilterValue = getTopBarFilerValueFromPath(pathname);
  dispatch(changeTopbarFilterValue(topBarFilterValue))
  const queryParams = new URLSearchParams(window.location.search);
  const sample = queryParams.get('sample');


  return (
    <Route
      {...rest}
      render={props =>


        siteInvalid === true ?
          <Redirect
            to={{
              pathname: '/invalid',
              state: { from: props.location }
            }}
          />
          :
          (isLoggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: sample ? '/signup' : '/signin',
                state: { from: props.location }
              }}
            />
          )
          )


      }
    />
  );
}

const PublicRoute = ({ component: Component, siteInvalid, ...rest }) => {
  const dispatch = useDispatch();
  if(rest.path !== PUBLIC_ROUTE.ALL_TITLES &&
    rest.path !== PUBLIC_ROUTE.ALL_EBOOK_TITLES &&
    rest.path !== PUBLIC_ROUTE.ALL_AUDIO_TITLES &&
    rest.path !== PUBLIC_ROUTE.ALL_VIDEO_TITLES &&
    rest.path !== PUBLIC_ROUTE.CATEGORY &&
    rest.path !== PUBLIC_ROUTE.CATEGORY_EBOOK_TITLES &&
    rest.path !== PUBLIC_ROUTE.CATEGORY_AUDIO_TITLES &&
    rest.path !== PUBLIC_ROUTE.CATEGORY_VIDEO_TITLES    
  ) {
    dispatch(changeOpenKeys(['Categories']));    
  }
  if(rest.path !== PUBLIC_ROUTE.CATEGORY &&
    rest.path !== PUBLIC_ROUTE.CATEGORY_EBOOK_TITLES &&
    rest.path !== PUBLIC_ROUTE.CATEGORY_AUDIO_TITLES &&
    rest.path !== PUBLIC_ROUTE.CATEGORY_VIDEO_TITLES
  ) {
    if(rest.path === PUBLIC_ROUTE.LANDING || rest.path === PUBLIC_ROUTE.TITLESET_TITLES) {
      dispatch(changeCurrent([]));
    }else{
      dispatch(changeCurrent(['all']));
    }  

  } 
  if(rest.path !== PUBLIC_ROUTE.ALL_EBOOK_TITLES &&
    rest.path !== PUBLIC_ROUTE.ALL_AUDIO_TITLES &&
    rest.path !== PUBLIC_ROUTE.ALL_VIDEO_TITLES &&
    rest.path !== PUBLIC_ROUTE.CATEGORY_EBOOK_TITLES &&
    rest.path !== PUBLIC_ROUTE.CATEGORY_AUDIO_TITLES &&
    rest.path !== PUBLIC_ROUTE.CATEGORY_VIDEO_TITLES
  ){
    dispatch(changeTopbarFilterValue('all'));
  }
  const buttonVisibility = getButtonVisibilityFromPath(rest.path);
  dispatch(changeTopbarButtonVisibility(buttonVisibility));
  return (
    <Route
    {...rest}
    render={props => {
      // Check if the path is OLD_SUBSCRIPTION then redirect
      if (rest.path === PUBLIC_ROUTE.OLD_SUBSCRIPTION) {
        const code = util.getQueryParam(props.location.search,"code");
        const planid = util.getQueryParam(props.location.search,"planid");
        const redirectPath =   planid || code ? `${PUBLIC_ROUTE.SUBSCRIPTION}?code=${code}&planid=${planid}` : PUBLIC_ROUTE.SUBSCRIPTION;
        return <Redirect to={redirectPath} />;
      }
      else if (rest.path === PUBLIC_ROUTE.OLD_RESET_PASSWORD) {
        const token = util.getQueryParam(props.location.search,"token");
        const redirectPath =   token ? `${PUBLIC_ROUTE.RESET_PASSWORD.replace(':token',token)}` : PUBLIC_ROUTE.FORGET_PASSWORD;
        return <Redirect to={redirectPath} />;
      }
      // Existing logic for siteInvalid
      return siteInvalid === true ? (
        <Redirect
          to={{
            pathname: '/invalid',
            state: { from: props.location }
          }}
        />
      ) : (
        <Component {...props} />
      );
    }}
  />

  );
}

export default function Routes() {
  const isLoggedIn = useSelector(state => state.Auth.idToken);
  let { siteName, siteFavIcon, siteInvalid } = useSelector(state => state.ThemeSwitcher);
  if (!siteName)
    siteName = "Papertrell";
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <Helmet>
            <title>{siteName}</title>
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href={siteFavIcon}
            />
          </Helmet>
          <Switch>
            {publicRoutes.map(singleRoute => {
              const { path, exact, ...otherProps } = singleRoute;
              return (
                <PublicRoute
                  exact={exact === false ? false : true}
                  key={path}
                  path={path}
                  siteInvalid={siteInvalid}
                  {...otherProps}
                />
              );
            })}
            {privateRoutes.map(route => {
              const { path, exact, ...otherProps } = route;
              return (
                <RestrictedRoute
                  exact={exact === false ? false : true}
                  key={path}
                  path={path}
                  siteInvalid={siteInvalid}
                  isLoggedIn={isLoggedIn}
                  {...otherProps}
                />
              );
            })}
            <Route
              path="/invalid"
              render={props =>
                siteInvalid === true ? (
                  <InvalidStore />
                ) : (
                  <Redirect
                    to={{
                      pathname: '/',
                      state: { from: props.location }
                    }}
                  />
                )
              }
            />
             <Route path="*" component={InvalidPage} />
          </Switch>

        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
